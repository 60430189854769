import React from 'react'
import ServicesHeader from './ServicesHeader'
import HandoverImage from './Assets/Images/ServicesInner/handover.jpg'

const Handover = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>COMMISSIONING</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& HANDOVER</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={HandoverImage} alt='Handover'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>System Commissioning:</strong> Testing and commissioning building systems and infrastructure to ensure proper operation.</li>
                            <li><strong>Handover and Training:</strong> Coordinating the handover of completed projects to clients and providing necessary training.</li>
                            <li><strong>Post-construction Support:</strong> Offering ongoing support and maintenance services after project completion.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Handover