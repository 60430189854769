import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import About from "./Components/About";
import Services from "./Components/Services";
import Projects from "./Components/Projects";
import ProjectDetail from "./Components/ProjectDetail";
import OurServices from "./Components/OurServices";
import ProjectManagement from "./Components/ProjectManagement";
import DesignAndEngineering from "./Components/DesignAndEngineering";
import ConstructionManagement from "./Components/ConstructionManagement";
import Procurement from "./Components/Procurement";
import CostControl from "./Components/CostControl";
import TechnologyInnovation from "./Components/TechnologyInnovation";
import RegulatoryCompliance from "./Components/RegulatoryCompliance";
import RiskManagement from "./Components/RiskManagement";
import ConstructionDocumentation from "./Components/ConstructionDocumentation";
import Handover from "./Components/Handover";
import FacilityManagement from "./Components/FacilityManagement";
import Footer from "./Components/Footer";
// import Progressbar from "./Components/Progress_bar";

function App() {
  return (
    <>
      <Navbar/>
      <BrowserRouter>
        <Routes>
          <Route index element = {<Home/>}/>
          <Route index path="/" element = {<Home/>}/>
          <Route path="about" element = {<About/>}></Route>
          <Route path="services" element = {<Services/>}></Route>
          <Route path="projects" element = {<Projects/>}></Route>
          <Route path="projectdetail" element = {<ProjectDetail/>}></Route>
          <Route path="ourservices" element = {<OurServices/>}></Route>
          <Route path="projectmanagement" element = {<ProjectManagement/>}></Route>
          <Route path="designandengineering" element = {<DesignAndEngineering/>}></Route>
          <Route path="constructionmanagement" element = {<ConstructionManagement/>}></Route>
          <Route path="procurement" element = {<Procurement/>}></Route>
          <Route path="costcontrol" element = {<CostControl/>}></Route>
          <Route path="technologyinnovation" element = {<TechnologyInnovation/>}></Route>
          <Route path="regulatorycompliance" element = {<RegulatoryCompliance/>}></Route>
          <Route path="riskmanagement" element = {<RiskManagement/>}></Route>
          <Route path="constructiondocumentation" element = {<ConstructionDocumentation/>}></Route>
          <Route path="handover" element = {<Handover/>}></Route>
          <Route path="facilitymanagement" element = {<FacilityManagement/>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </>
  );
}

export default App;
