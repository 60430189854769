import React from 'react'
import ServicesHeader from './ServicesHeader'
import documentation from './Assets/Images/ServicesInner/documentation.jpg'

const ConstructionDocumentation = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>CONSTRUCTION</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>DOCUMENTATION</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={documentation} alt='documentation'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Documentation Management:</strong> Managing and maintaining project documentation, including contracts, drawings, and reports.</li>
                            <li><strong>Progress Reporting:</strong> Providing regular progress reports to stakeholders, including status updates and performance metrics</li>
                            <li><strong>Change Management:</strong> Managing changes to project scope, schedule, and budget, and documenting all changes.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConstructionDocumentation