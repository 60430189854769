import React from 'react'
import ServicesHeader from './ServicesHeader'
import ProcurementSupplyChain from './Assets/Images/ServicesInner/procurement.jpg'

const Procurement = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>PROCUREMENT</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& SUPPLY CHAIN</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={ProcurementSupplyChain} alt='Procurement & Supply Chain'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Vendor Management:</strong> Selecting and managing relationships with suppliers and subcontractors.</li>
                            <li><strong>Material Procurement:</strong> Sourcing and procuring materials and equipment required for construction.</li>
                            <li><strong>Logistics Management:</strong> Coordinating the transportation and delivery of materials and equipment to the construction site.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Procurement