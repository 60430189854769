import React from 'react'
import ServicesHeader from './ServicesHeader'
import Compliance from './Assets/Images/ServicesInner/compliance.jpg'

const RegulatoryCompliance = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>PROCUREMENT</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& SUPPLY CHAIN</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={Compliance} alt='Compliance'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Permit Acquisition:</strong> Assisting in obtaining necessary permits and approvals from regulatory authorities.</li>
                            <li><strong>Regulatory Compliance:</strong> Ensuring compliance with local, state, and federal regulations and building codes.</li>
                            <li><strong>Environmental Compliance:</strong> Managing environmental assessments and ensuring compliance with environmental regulations.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default RegulatoryCompliance