import React from 'react'
import AboutHeader from './AboutHeader'
import AboutSlider1 from './Assets/Images/AboutSlider/about-slider-1.jpg'
import AboutSlider2 from './Assets/Images/AboutSlider/about-slider-2.jpg'
import AboutSlider3 from './Assets/Images/AboutSlider/about-slider-3.jpg'
import { MDBCarousel, MDBCarouselItem, MDBBtn } from 'mdb-react-ui-kit';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const About = () => {
  return (
    <>
        <AboutHeader/>
        <div className='container'>
            <div className='row mt-4'>
                <div className='col-md-3 text-center'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={AboutSlider1} className='d-block w-100 about-slider' alt='AboutSlider1' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={AboutSlider2} className='d-block w-100 about-slider' alt='AboutSlider2' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={AboutSlider3} className='d-block w-100 about-slider' alt='AboutSlider3' />
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
                <div className='col-md-9'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 700, fontSize: 30, borderRightColor: '#F5BF23'}}>25</span><br/>Years of Experience
                    </p>
                    <p>
                        <span style={{fontWeight: 500, fontSize:40, color: 'black'}}>Welcome to Eustan Smart Infracon!</span>
                    </p>
                    <p align='justify' style={{color: 'black'}}>Founded in 2023, Eustan Smart Infracon is a dynamic and innovative construction infrastructure management company dedicated to transforming the landscape of modern infrastructure. We are committed to delivering excellence in every project, leveraging cutting-edge technology, sustainable practices, and a customer-centric approach to meet the evolving needs of our clients.</p>
                    <p>
                        <span style={{fontWeight: 500, fontSize:30, color: 'black'}}>Our Vision:</span>
                    </p>
                    <p align='justify' style={{color: 'black'}}>At Eustan Smart Infracon, our vision is to be a leading force in the construction industry, renowned for our ability to blend innovation with practical solutions. We aim to build a better future by creating infrastructure that is not only functional and reliable but also sustainable and aesthetically pleasing.</p>
                    {/* <p></p>
                    <p>
                        <MDBBtn className='eustan-btn'>Get In Touch</MDBBtn>
                    </p> */}
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <p>
                        <span style={{fontWeight: 500, fontSize:30, color: 'black'}}>Our Mission:</span>
                    </p>
                    <p align='justify' style={{color: 'black'}}>Our mission is to provide top-tier construction and infrastructure management services that exceed our clients' expectations. We strive to achieve this through meticulous planning, robust project management, and an unwavering commitment to quality and safety.</p>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 400, fontSize: 40}}>WHAT</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}> WE DO</span>
                    </p>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Project Management
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>From initial concept to final completion, we manage every aspect of your project with precision. Our team of experienced project managers ensures timely delivery and budget adherence, while maintaining the highest standards of quality.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Design & Engineering
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>Our talented architects and engineers work collaboratively to create innovative designs that meet your specific requirements. We specialize in structural, civil, and MEP engineering, ensuring your project is built to last.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Construction Management
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>We oversee all phases of construction, providing on-site supervision, quality control, and safety management. Our hands-on approach ensures that every detail is executed flawlessly.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Procurement & Supply Chain
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>With a keen eye for quality and cost-efficiency, we manage the procurement of materials and equipment, ensuring timely delivery and seamless integration into your project.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Technology Integration
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>Embracing the latest in construction technology, we utilize Building Information Modeling (BIM), drones, and automation to enhance accuracy, efficiency, and innovation in our projects.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className='col-md-4 mb-2 my-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Sustainability Practices
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <p align='justify'>We are committed to green building practices and sustainability. Our projects incorporate eco-friendly materials and energy-efficient designs to minimize environmental impact and promote long-term sustainability.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
        <div className='container-fluid eustan-specialization'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 400, fontSize: 40}}>OUR</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}> SPECIALIZATION</span>
                    </p>
                </div>
            </div>
            <div className='row'>
            <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <img width="100" height="100" src="https://img.icons8.com/emoji/100/building-construction.png" alt="building-construction"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Construction</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>1</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/bubbles/100/building.png" alt="building"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Renovation</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>2</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/stickers/100/online-maintenance-portal.png" alt="online-maintenance-portal"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Maintenance</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>3</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/papercut/100/design.png" alt="design"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Architecture Design</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>4</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default About