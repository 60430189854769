import React from 'react'
import ServicesHeader from './ServicesHeader'
import EngineerImage from './Assets/Images/engineer-service.png'

const Services = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 text-center mt-4 mb-3'>
                    <img className='img-fluid' src={EngineerImage} alt='Engineer'/>
                </div>
                <div className='col-md-6 mt-4'>
                    <span style={{color: 'black', fontSize: 35, fontWeight: 400}}>In our work we have pride, quality is what we provide.</span>
                    <p align='justify' style={{color: 'black'}}>Eustan Smart Infracon offer a variety of services aimed at ensuring the successful planning, execution, and maintenance of construction projects. These services encompass the entire lifecycle of infrastructure projects, from initial planning and design to construction and ongoing maintenance.</p>
                    <div className='container-fluid'>
                        <div className='row text-center'>
                            <div className='col-md-4' style={{backgroundColor: '#F5BF23', color: 'black'}}>Call For a Quote: <br/>(321) 456 6789</div>
                            <div className='col-md-8' style={{backgroundColor: 'black', color: 'white'}}>
                                <p>Online Estimate Form</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid eustan-specialization'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 400, fontSize: 40}}>ALL</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}> SERVICES</span>
                    </p>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/60/external-project-management-engineering-flaticons-lineal-color-flat-icons.png" alt="external-project-management-engineering-flaticons-lineal-color-flat-icons"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Project Management</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>1</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Project Planning and Scheduling:</strong> Developing detailed project plans, timelines, and schedules to ensure timely completion.</li>
                                        <li><strong>Budget Management:</strong> Preparing and managing project budgets, including cost estimation, tracking, and reporting.</li>
                                        <li><strong>Resource Management:</strong> Allocating and managing resources, including labor, equipment, and materials.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-flat-icons-vectorslab/60/external-Home-Renovation-real-estate-flat-icons-vectorslab.png" alt="external-Home-Renovation-real-estate-flat-icons-vectorslab"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black', textAlign: 'center'}}>Design & Engineering</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>2</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Architectural Design:</strong> Creating architectural designs and blueprints for buildings and infrastructure projects.</li>
                                        <li><strong>Structural Engineering:</strong> Designing structural elements to ensure safety, stability, and compliance with regulations.</li>
                                        <li><strong>Civil Engineering:</strong> Planning and designing infrastructure such as roads, bridges, tunnels, and drainage systems.</li>
                                        <li><strong>MEP Engineering:</strong> Designing mechanical, electrical, and plumbing systems for buildings and infrastructure.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <img width="60" height="60" src="https://img.icons8.com/emoji/60/building-construction.png" alt="building-construction"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}} className='text-center'>Construction Management</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>3</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>On-site Supervision:</strong> Providing on-site management and supervision to ensure adherence to plans and specifications.</li>
                                        <li><strong>Quality Control:</strong> Implementing quality control measures to ensure the highest standards of construction.</li>
                                        <li><strong>Safety Management:</strong> Ensuring compliance with safety regulations and promoting safe work practices on construction sites.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2 text-center'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-flaticons-flat-flat-icons/60/external-interior-design-design-flaticons-flat-flat-icons.png" alt="external-interior-design-design-flaticons-flat-flat-icons"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Procurement/Supply Chain</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>4</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Vendor Management:</strong> Selecting and managing relationships with suppliers and subcontractors.</li>
                                        <li><strong>Material Procurement:</strong> Sourcing and procuring materials and equipment required for construction.</li>
                                        <li><strong>Logistics Management:</strong> Coordinating the transportation and delivery of materials and equipment to the construction site.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2 text-center'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/60/external-opinion-lifestyles-flaticons-lineal-color-flat-icons-2.png" alt="external-opinion-lifestyles-flaticons-lineal-color-flat-icons-2"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}} className='text-center'>Cost Estimation & Control</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>5</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Cost Estimation:</strong> Providing detailed cost estimates for projects during the planning phase.</li>
                                        <li><strong>Cost Control:</strong> Monitoring and controlling project costs to prevent budget overruns.</li>
                                        <li><strong>Value Engineering:</strong> Analyzing project designs to identify cost-saving opportunities without compromising quality.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2 text-center'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/external-engineering-avatar-and-occupation-justicon-lineal-color-justicon.png" alt="external-engineering-avatar-and-occupation-justicon-lineal-color-justicon"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}> Technology & Innovation</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>6</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Building Information Modeling (BIM):</strong> Using BIM for 3D modeling, clash detection, and project visualization.</li>
                                        <li><strong>Construction Automation:</strong> Implementing automation technologies such as drones, robotics, and automated machinery.</li>
                                        <li><strong>Sustainability Practices:</strong> Integrating sustainable construction practices and materials to minimize environmental impact.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-flaticons-flat-flat-icons/60/external-builder-home-improvement-flaticons-flat-flat-icons-2.png" alt="external-builder-home-improvement-flaticons-flat-flat-icons-2"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}> Permitting/Regulatory Compliance</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>7</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Permit Acquisition:</strong> Assisting in obtaining necessary permits and approvals from regulatory authorities.</li>
                                        <li><strong>Regulatory Compliance:</strong> Ensuring compliance with local, state, and federal regulations and building codes.</li>
                                        <li><strong>Environmental Compliance:</strong> Managing environmental assessments and ensuring compliance with environmental regulations.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/arcade/60/lightning-bolt.png" alt="lightning-bolt"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Risk Management</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>8</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Risk Assessment:</strong> Identifying and assessing potential risks associated with the project.</li>
                                        <li><strong>Risk Mitigation:</strong> Developing and implementing strategies to mitigate identified risks.</li>
                                        <li><strong>Insurance Management:</strong> Managing insurance policies and claims related to construction activities.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2 text-center'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-10'>
                                <img width="60" height="60" src="https://img.icons8.com/external-others-pike-picture/60/external-Documentation-logistics-others-pike-picture-2.png" alt="external-Documentation-logistics-others-pike-2"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}> Construction Documentation</span>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>9</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Documentation Management:</strong> Managing and maintaining project documentation, including contracts, drawings, and reports.</li>
                                        <li><strong>Progress Reporting:</strong> Providing regular progress reports to stakeholders, including status updates and performance metrics</li>
                                        <li><strong>Change Management:</strong> Managing changes to project scope, schedule, and budget, and documenting all changes.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-9'>
                                <img width="60" height="60" src="https://img.icons8.com/external-goofy-color-kerismaker/60/external-Handover-real-estate-goofy-color-kerismaker.png" alt="external-Handover-real-estate-goofy-color-kerismaker"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}> Commissioning & Handover</span>
                                </div>
                                <div className='col-md-3 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>10</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>System Commissioning:</strong> Testing and commissioning building systems and infrastructure to ensure proper operation.</li>
                                        <li><strong>Handover and Training:</strong> Coordinating the handover of completed projects to clients and providing necessary training.</li>
                                        <li><strong>Post-construction Support:</strong> Offering ongoing support and maintenance services after project completion.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-9'>
                                <img width="60" height="60" src="https://img.icons8.com/stickers/60/management.png" alt="management"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Facility Management</span>
                                </div>
                                <div className='col-md-3 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:50}}>11</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <p align='justify' style={{color: 'black'}}>
                                    <ul>
                                        <li><strong>Maintenance Planning:</strong> Developing and implementing maintenance plans for infrastructure and facilities.</li>
                                        <li><strong>Repair and Renovation:</strong> Managing repair and renovation projects to maintain the functionality and aesthetics of buildings.</li>
                                        <li><strong>Energy Management:</strong> Implementing energy-efficient practices and technologies to reduce operational costs.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Services