import React from 'react'
import ServicesHeader from './ServicesHeader'
import Facility from './Assets/Images/ServicesInner/facility.jpg'

const FacilityManagement = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>FACILITY</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>MANAGEMENT</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={Facility} alt='Facility'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Maintenance Planning:</strong> Developing and implementing maintenance plans for infrastructure and facilities.</li>
                            <li><strong>Repair and Renovation:</strong> Managing repair and renovation projects to maintain the functionality and aesthetics of buildings.</li>
                            <li><strong>Energy Management:</strong> Implementing energy-efficient practices and technologies to reduce operational costs.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default FacilityManagement