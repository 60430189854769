import React from 'react'
import HomeSlider1 from './Assets/Images/HomeSlider/home-slider-1.png'
import HomeSlider2 from './Assets/Images/HomeSlider/home-slider-2.png'
import HomeSlider3 from './Assets/Images/HomeSlider/home-slider-3.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
const HomeSlider = () => {
  return (
    <>
        <MDBCarousel showControls>
            <MDBCarouselItem itemId={1}>
                <img src={HomeSlider1} className='d-block w-100 home-slider' alt='HomeSlider1' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2}>
                <img src={HomeSlider2} className='d-block w-100 home-slider' alt='HomeSlider2' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
                <img src={HomeSlider3} className='d-block w-100 home-slider' alt='HomeSlider3' />
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default HomeSlider