import React from 'react'
import ServicesHeader from './ServicesHeader'
import CostControlImage from './Assets/Images/ServicesInner/cost-control.jpg'

const CostControl = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>COST ESTIMATION</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& CONTROL</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={CostControlImage} alt='Cost Control'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Cost Estimation:</strong> Providing detailed cost estimates for projects during the planning phase.</li>
                            <li><strong>Cost Control:</strong> Monitoring and controlling project costs to prevent budget overruns.</li>
                            <li><strong>Value Engineering:</strong> Analyzing project designs to identify cost-saving opportunities without compromising quality.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default CostControl