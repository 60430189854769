import React from 'react'
import ProjectHeaderImage from './Assets/Images/projects-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const ProjectsSlider = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
              <img src={ProjectHeaderImage} className='d-block w-100 home-slider' alt='ServiceHeader' />
          </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default ProjectsSlider