import React from 'react'
import ServiceHeader from './Assets/Images/services-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const ServicesHeader = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
              <img src={ServiceHeader} className='d-block w-100 home-slider' alt='ServiceHeader' />
          </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default ServicesHeader