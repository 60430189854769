import React from 'react'
import OurServicesSlider from './OurServicesSlider'
import technology from './Assets/Images/ServicesInner/technology-innovation.jpg'

const TechnologyInnovation = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>TECHNOLOGY</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& INNOVATION</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={technology} alt='technology'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Building Information Modeling (BIM):</strong> Using BIM for 3D modeling, clash detection, and project visualization.</li>
                            <li><strong>Construction Automation:</strong> Implementing automation technologies such as drones, robotics, and automated machinery.</li>
                            <li><strong>Sustainability Practices:</strong> Integrating sustainable construction practices and materials to minimize environmental impact.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default TechnologyInnovation