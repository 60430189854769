import React from 'react'
import ServicesHeader from './ServicesHeader'
import DesignAndEngineeringImage from './Assets/Images/ServicesInner/design-engineering.jpg'

const DesignAndEngineering = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>DESIGN</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>& ENGINEERING</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={DesignAndEngineeringImage} alt='Design & Engineering'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Architectural Design:</strong> Creating architectural designs and blueprints for buildings and infrastructure projects.</li>
                            <li><strong>Structural Engineering:</strong> Designing structural elements to ensure safety, stability, and compliance with regulations.</li>
                            <li><strong>Civil Engineering:</strong> Planning and designing infrastructure such as roads, bridges, tunnels, and drainage systems.</li>
                            <li><strong>MEP Engineering:</strong> Designing mechanical, electrical, and plumbing systems for buildings and infrastructure.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default DesignAndEngineering