import React from 'react'
import OurServicesSlider from './OurServicesSlider'
import ProjectManagementInner from './Assets/Images/ServicesInner/project-management.jpg'

const ProjectManagement = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>PROJECT</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>MANAGEMENT</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={ProjectManagementInner} alt='Project Management'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>Project Planning and Scheduling:</strong> Developing detailed project plans, timelines, and schedules to ensure timely completion.</li>
                            <li><strong>Budget Management:</strong> Preparing and managing project budgets, including cost estimation, tracking, and reporting.</li>
                            <li><strong>Resource Management:</strong> Allocating and managing resources, including labor, equipment, and materials.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProjectManagement