import React from 'react'
import ServicesHeader from './ServicesHeader'
import ConstructionManagementImage from './Assets/Images/ServicesInner/construction-management.jpg'

const ConstructionManagement = () => {
  return (
    <>
        <ServicesHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>CONSTRUCTION</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>MANAGEMENT</span>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={ConstructionManagementImage} alt='Design & Engineering'/>
                </div>
                <div className='col-md-6'>
                    <p align='justify' style={{color: 'black'}}>
                        <ul>
                            <li><strong>On-site Supervision:</strong> Providing on-site management and supervision to ensure adherence to plans and specifications.</li>
                            <li><strong>Quality Control:</strong> Implementing quality control measures to ensure the highest standards of construction.</li>
                            <li><strong>Safety Management:</strong> Ensuring compliance with safety regulations and promoting safe work practices on construction sites.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConstructionManagement