import React, { useState } from 'react'
import EustanLogo from './Assets/Images/eustan-logo-web.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse
  } from 'mdb-react-ui-kit';

const Navbar = () => {
    const [openNavRight, setOpenNavRight] = useState(false);
  return (
    <>
        <MDBNavbar expand='lg' light bgColor='light' sticky='top'>
      <MDBContainer fluid>
        <MDBNavbarBrand href='/'>
            <img src={EustanLogo} className='img-fluid' alt='Eustan Infra Logo'/>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          data-target='#navbarRightAlignExample'
          aria-controls='navbarRightAlignExample'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenNavRight(!openNavRight)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar open={openNavRight}>
          <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0 ms-auto'>
            <MDBNavbarItem><MDBNavbarLink active aria-current='page' href='/' className='navblock'>HOME</MDBNavbarLink></MDBNavbarItem>
            <MDBNavbarItem><MDBNavbarLink active href='about' className='navblock'>ABOUT US</MDBNavbarLink></MDBNavbarItem>
            {/* <MDBNavbarItem><MDBNavbarLink active href='services' className='navblock'>SERVICES</MDBNavbarLink></MDBNavbarItem> */}
            <MDBNavbarItem>
              {/* <MDBNavbarLink active href='ourservices' className='navblock'>SERVICES LANDING</MDBNavbarLink> */}
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                  OUR SERVICES
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem><a href='projectmanagement'>PROJECT MANAGEMENT</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='designandengineering'>DESIGN ENGINEERING</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='constructionmanagement'>CONSTRUCTION MANAGEMENT</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='procurement'>PROCUREMENT SUPPLY CHAIN</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='costcontrol'>COST ESTIMATE & CONTROL</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='technologyinnovation'>TECHNOLOGY & INNOVATION</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='regulatorycompliance'>REGULATORY COMPLIANCE</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='riskmanagement'>RISK MANAGEMENT</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='handover'>COMMISSIONING & HANDOVER</a></MDBDropdownItem>
                  <MDBDropdownItem><a href='facilitymanagement'>FACILITY MANAGEMENT</a></MDBDropdownItem>

                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem><MDBNavbarLink active href='#' className='navblock'>CONTACT US</MDBNavbarLink></MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
    </>
  )
}

export default Navbar