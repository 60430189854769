import React from 'react'
import AboutHeader from './AboutHeader'
import Project11 from './Assets/Images/Project1/project1-1.jpg'
import Project12 from './Assets/Images/Project1/project1-2.jpg'
import Project13 from './Assets/Images/Project1/project1-3.jpg'
import Project14 from './Assets/Images/Project1/project1-4.jpg'
import HomeSlider from './HomeSlider'
const ProjectDetail = () => {
  return (
    <>
        <AboutHeader/>
        <div className='container'>
            <div className='row mt-4'>
                <div className='col-md-3 my-3'>
                    <img className='img-fluid' src={Project11} alt='Project1-1'/>
                </div>
                <div className='col-md-3 my-3'>
                    <img className='img-fluid' src={Project12} alt='Project1-2'/>
                </div>
                <div className='col-md-3 my-3'>
                    <img className='img-fluid' src={Project13} alt='Project1-3'/>
                </div>
                <div className='col-md-3 my-3'>
                    <img className='img-fluid' src={Project14} alt='Project1-4'/>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-3 my-3'>
                    <p>
                        <span style={{color: 'black', fontSize: 25, fontWeight: 500}}>DATE</span>
                    </p>
                    <p>
                        <span style={{color: 'black'}}>April 10, 2022</span>
                    </p>
                    <p>
                        <span style={{color: 'black', fontSize: 25, fontWeight: 500}}>Client</span>
                    </p>
                    <p>
                        <span style={{color: 'black'}}>Studio Massimo, Italy</span>
                    </p>
                    <p>
                        <span style={{color: 'black', fontSize: 25, fontWeight: 500}}>Project type</span>
                    </p>
                    <p>
                        <span style={{color: 'black'}}>Contruction, Brading</span>
                    </p>
                    <p>
                        <span style={{color: 'black', fontSize: 25, fontWeight: 500}}>Location</span>
                    </p>
                    <p>
                        <span style={{color: 'black'}}>Mountain View CA 94043</span>
                    </p>
                    <p>
                        <span style={{color: 'black', fontSize: 25, fontWeight: 500}}>Year</span>
                    </p>
                    <p>
                        <span style={{color: 'black'}}>2022</span>
                    </p>
                </div>
                <div className='col-md-9 my-3'>
                    <HomeSlider/>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <p className='h2' style={{color: 'black'}}>
                    Creating a sustainable future through building preservation, green architecture, and smart design
                    </p>
                </div>
                <div className='col-12'>
                    <p align='justify' style={{color: 'black'}}>
                    Designers think everything done by someone else is awful, and that they could do it better themselves, which explains why I designed my own living room carpet, I suppose. the architect represents neither a Dionysian nor an Apollinian condition: here it is the mighty act of will, the will which moves mountains, the intoxication of the strong will, which demands artistic expression. The most powerful men have always inspired the architects; the architect has always been influenced by power.
                    </p>
                    <p align='justify' style={{color: 'black'}}>
                    Tincidunt integer euaugue augue nunc elit dolor luctus placerat scelerisque euismod lorem. Utenim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Tincidunt integer eu augue augue nunc elit dolor, luctus placerat scelerisque euismod, iaculis eu lacus nunc mi elite sit amet justo nunc tempore. Lorem ipsum dolor sit amet consectetur adipiscing elitsed do eiusmod tempor incididunt ut ore magna aliqua. Arullamco laboris nisi ut aliquip ex ea commodo. Tincidunt integer eu augue augue nunc elit dolor, luctus placerat scelerisque euismod, iaculis eu lacus nunc mi elite sit amet justo nunc tempore. Lorem ipsum dolor sit amet consectetur adipiscing elitsed do eiusmod tempor incididunt ut ore magna aliqua.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProjectDetail