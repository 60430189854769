import React from 'react'
import ProjectsSlider from './ProjectsSlider'
import Project1 from './Assets/Images/Projects/project-1.jpg'
import Project2 from './Assets/Images/Projects/project-2.jpg'
import Project3 from './Assets/Images/Projects/project-3.jpg'
import Project4 from './Assets/Images/Projects/project-4.jpg'
import Project5 from './Assets/Images/Projects/project-5.jpg'
import Project6 from './Assets/Images/Projects/project-6.jpg'
import Project7 from './Assets/Images/Projects/project-7.jpg'
import Project8 from './Assets/Images/Projects/project-8.jpg'
// import Project9 from './Assets/Images/Projects/project-9.jpg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Projects = () => {
  return (
    <>
        <ProjectsSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project1}
                            title="Project1"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Stronger communities
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small" href='projectdetail'>Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project2}
                            title="Project2"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Concept to creation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project3}
                            title="Project3"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Safety for life
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project4}
                            title="Project4"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Whatever it takes
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project5}
                            title="Project5"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            We build tomorrow
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project6}
                            title="Project6"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            The key to our success
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project7}
                            title="Project7"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Rising above
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 my-2'>
                    <Card sx={{ maxWidth: 500 }}>
                        <CardMedia
                            sx={{ height: 300 }}
                            image={Project8}
                            title="Project8"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            From concept to creation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            This is ipsum dolor sitamet, consectetur adipisi cing elito, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small">Share</Button> */}
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    </>
  )
}

export default Projects