import React from 'react'
import OurServicesSlider from './OurServicesSlider'
import ProjectManagement from './Assets/Images/Services/project-management.png'
import DesignEngineering from './Assets/Images/Services/design-engineering.png'
import ConstructionManagement from './Assets/Images/Services/construction-management.png'
import SupplyChain from './Assets/Images/Services/supply-chain.png'
import CostEstimate from './Assets/Images/Services/cost-estimate.png'
import Technology from './Assets/Images/Services/technology.png'
import Compliance from './Assets/Images/Services/compliance.png'
import RiskManagement from './Assets/Images/Services/risk-management.png'
import ConstructionDocumentation from './Assets/Images/Services/construction-documentation.png'
import Handover from './Assets/Images/Services/handover.png'
import FacilityManagement from './Assets/Images/Services/facility-management.png'

const OurServices = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center mt-3'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>OUR</span> <span style={{color:'black', fontWeight: 600, fontSize: 40}}>SERVICES</span>
                    </p>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-md-3 my-2'>
                    <a href="projectmanagement">
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={ProjectManagement} alt='Project Management'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>PROJECT</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>MANAGEMENT</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href="designandengineering">
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={DesignEngineering} alt='Design Engineering'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>DESIGN</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>ENGINEERING</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='constructionmanagement'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={ConstructionManagement} alt='Construction Management'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>CONSTRUCTION</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>MANAGEMENT</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='procurement'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={SupplyChain} alt='Supply Chain'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>PROCUREMENT</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>SUPPLY CHAIN</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='costcontrol'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={CostEstimate} alt='Cost Estimate'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>COST ESTIMATE</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>& CONTROL</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='technologyinnovation'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={Technology} alt='Technology'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>TECHNOLOGY</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>& INNOVATION</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='regulatorycompliance'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={Compliance} alt='Compliance'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>REGULATORY</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>COMPLIANCE</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='riskmanagement'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={RiskManagement} alt='RiskManagement'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>RISK</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>MANAGEMENT</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='constructiondocumentation'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={ConstructionDocumentation} alt='ConstructionDocumentation'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>CONSTRUCTION</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>DOCUMENTATION</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='handover'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={Handover} alt='Handover'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>COMMISSIONING</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>& HANDOVER</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className='col-md-3 my-2'>
                    <a href='facilitymanagement'>
                        <div className='card shadow eustan-service-box text-center'>
                            <img className='img-fluid' src={FacilityManagement} alt='FacilityManagement'/>
                            <p className='mt-3'>
                                <span style={{color: '#f5bf23', fontSize: '30px', fontWeight: 500}}>FACILITY</span>
                                <br/>
                                <span style={{color: 'black', fontSize: '30px', fontWeight: 300}}>MANAGEMENT</span>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </>
  )
}

export default OurServices