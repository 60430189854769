import React from 'react'
import HomeSlider from './HomeSlider'
import Worker1 from './Assets/Images/worker-1.jpg'
import Worker2 from './Assets/Images/worker-2.jpg'
import Worker3 from './Assets/Images/worker-3.jpg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import HomeAbout from './HomeAbout'
import Progressbar from "./Progress_bar";
import Mission1 from './Assets/Images/mission1.jpg'
import { MDBInput, MDBTextArea, MDBBtn } from 'mdb-react-ui-kit';

const Home = () => {
  return (
    <>
        <HomeSlider/>
        <div className='container-fluid'>
            <div className='row mt-4 text-center mb-4'>
                <div className='col-md-3 text-center my-2'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 400 }}
                            image={Worker2}
                            title="Worker 2"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Architecture
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Life is Architecture.<Button><span className='display-6'><a href = 'services'>+</a></span></Button>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 text-center my-2'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 400 }}
                            image={Worker1}
                            title="Worker 1"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Construction
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Engineering your dreams with us.<Button><span className='display-6'><a href = 'services'>+</a></span></Button>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 text-center my-2'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 400 }}
                            image={Worker3}
                            title="Worker 3"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Renovation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Dazzling Design<Button><span className='display-6'><a href = 'services'>+</a></span></Button>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </div>
                <div className='col-md-3 text-center my-2'>
                    <Card sx={{ maxWidth: 345 }}>
                        {/* <CardMedia
                            sx={{ height: 400 }}
                            image={Worker3}
                            title="Worker 3"
                        /> */}
                        <CardContent>
                            {/* <Typography gutterBottom variant="h5" component="div">
                            Renovation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Dazzling Design<Button><span className='display-6'>+</span></Button>
                            </Typography> */}
                            <Typography>
                                <span style={{fontWeight: 100, fontSize: '34px' }}>Building</span>
                            </Typography>
                            <Typography>
                                <span className='display-6' style={{fontWeight: 400}}>It better in concrete.</span>
                            </Typography>
                            <Typography>
                                <p className='mt-4' align='justify'>Eustan Smart Infracon is a dynamic and innovative construction infrastructure management company dedicated to transforming the landscape of modern infrastructure.</p>
                            </Typography>
                            <a href='about'><Button className='eustan-primary'>KNOW MORE</Button></a>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row home-about'>
                <div className='col-12 text-center mt-3'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>ABOUT</span> <span style={{color:'white', fontWeight: 600, fontSize: 40}}>EUSTAN SMART INFRACON</span>
                    </p>
                </div>
                <div className='col-md-8 mt-4 mb-4'>
                    <HomeSlider/>
                </div>
                <div className='col-md-4 mt-4 mb-4'>
                    <Card sx={{ maxWidth: "100%" }}>
                        <CardContent>
                            <Typography>
                                <span style={{fontWeight: 100, fontSize: '34px' }}>Committed </span> <span className='display-6' style={{fontWeight: 400}}>to superior quality and results.</span>
                            </Typography>
                            <Typography>
                                <p></p><p className='mt-2' align='justify'><strong>Founded in 2023, Eustan Smart Infracon is a dynamic and innovative construction infrastructure management company dedicated to transforming the landscape of modern infrastructure.</strong><p></p> We are committed to delivering excellence in every project, leveraging cutting-edge technology, sustainable practices, and a customer-centric approach to meet the evolving needs of our clients.</p>
                                {/* <p align='justify'>There are many variations of passages of lorem available, but the majority have suffered alteration in some form, by inject humour, or randomised words which don't look even slightly believable.</p> */}
                            </Typography>
                            <a href='about'><Button className='eustan-primary'>KNOW MORE</Button></a>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-md-4'>
                    <p className='text-center'>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>OUR</span> <span style={{color:'BLACK', fontWeight: 600, fontSize: 40}}>VISION</span>
                    </p>
                    <p align='justify'>At Eustan Smart Infracon, our vision is to be a leading force in the construction industry, renowned for our ability to blend innovation with practical solutions. We aim to build a better future by creating infrastructure that is not only functional and reliable but also sustainable and aesthetically pleasing.</p>
                    {/* <p className='text-center'>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>OUR</span> <span style={{color:'BLACK', fontWeight: 600, fontSize: 40}}>MISSION</span>
                    </p>
                    <p align='justify'>Our mission is to provide top-tier construction and infrastructure management services that exceed our clients' expectations. We strive to achieve this through meticulous planning, robust project management, and an unwavering commitment to quality and safety.</p> */}
                    {/* <p><iframe width="400" height="315" src="https://www.youtube.com/embed/x4dEfxj_Ttk?si=eHCfEMUrSOHR57e2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p> */}
                </div>
                <div className='col-md-4'>
                    <div className='container-fluid'>
                        <div className='row mt-4'>
                            <div className='col-12 text-center my-2'>
                                <div className='card shadow' style={{backgroundColor: 'black'}}>
                                    <p style={{color: '#F5BF23', fontWeight: 700, fontSize: 30}}>
                                        450
                                    </p>
                                    <p style={{color: 'white', fontSize: 30}}>Active Experts</p>
                                </div>
                            </div>
                            <div className='col-12 text-center my-2'>
                                <div className='card shadow' style={{backgroundColor: 'black'}}>
                                    <p style={{color: '#F5BF23', fontWeight: 700, fontSize: 30}}>
                                        670
                                    </p>
                                    <p style={{color: 'white', fontSize: 30}}>Satisfied Clients</p>
                                </div>
                            </div>
                            <div className='col-12 text-center my-2'>
                                <div className='card shadow' style={{backgroundColor: 'black'}}>
                                    <p style={{color: '#F5BF23', fontWeight: 700, fontSize: 30}}>
                                    1500
                                    </p>
                                    <p style={{color: 'white', fontSize: 30}}>Project Complete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <p>
                        <span style={{fontWeight: 100, fontSize: 30, color: 'black'}}>We Have</span> <span style={{color: '#F5BF23', fontWeight: 700, fontSize: 30}}>15 years</span> <span style={{fontWeight: 500, fontSize: 30, color: 'black'}}>experience in construction</span>
                    </p>
                    Architecture: <Progressbar
                        bgcolor="#F5BF23"
                        progress="85"
                        height={5}
                    />
                    Construction: <Progressbar
                        bgcolor="#F5BF23"
                        progress="78"
                        height={5}
                    />
                    Building: <Progressbar
                        bgcolor="#F5BF23"
                        progress="65"
                        height={5}
                    />
                    Interior: <Progressbar
                        bgcolor="#F5BF23"
                        progress="40"
                        height={5}
                    />
                    Commercial: <Progressbar
                        bgcolor="#F5BF23"
                        progress="55"
                        height={5}
                    />
                </div>
            </div>
        </div>
        <div className='container-fluid our-mission'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p>
                        <span style={{color: '#F5BF23', fontWeight: 100, fontSize: 40}}>OUR</span> <span style={{color: 'black', fontWeight: 600, fontSize: 40}}> MISSION</span>
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    {/* <p>
                        <span style={{color: 'black', fontWeight: 100, fontSize: 40}}> Site Areas</span><span style={{color: 'black', fontWeight: 600, fontSize: 40}}> what we serve</span>
                    </p>
                    <p align='justify'>
                    lorem Ipsum available, but the majority have suffered alteration in some form, by ious versi ons have evolved over the years, sometimes by accident
                    </p>
                    <p>
                        <ul>
                            <li className='eustan-bullet'>Construction Management</li>
                            <li className='eustan-bullet'>Pre Construction Services</li>
                            <li className='eustan-bullet'>Contract Administration</li>
                            <li className='eustan-bullet'>Implementation</li>
                            <li className='eustan-bullet'>Leed consultation</li>
                        </ul>
                    </p> */}
                    <p align='justify'>Our mission is to provide top-tier construction and infrastructure management services that exceed our clients' expectations. We strive to achieve this through meticulous planning, robust project management, and an unwavering commitment to quality and safety.</p>
                </div>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={Mission1} alt='Mission 1'/>
                </div>
                <div className='col-md-4'>
                    <div className='card'>
                        <p><span style={{fontWeight: 100, fontSize: 30}}>Get In</span><span style={{fontWeight: 500, fontSize: 30}}> Touch</span></p>
                        <p><MDBInput label="Full Name" id="form1" type="text" style={{color: "white"}} className='mb-3' /></p>
                        <p><MDBInput label="Email Id" id="form1" type="email" style={{color: "white"}} className='mb-3' /></p>
                        <p><MDBInput label="Mobile Number" id="form1" type="tel" style={{color: "white"}} className='mb-3' /></p>
                        <p><MDBInput label="Subject" id="form1" type="text" style={{color: "white"}} className='mb-3' /></p>
                        <p><MDBTextArea label="Message" id="textAreaExample" rows="{4}" /></p>
                        <p><MDBBtn className='eustan-btn'>Send Now</MDBBtn></p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row home-about'>
                <div className='col-12 text-center mt-3'>
                    <p>
                        <span style={{color:'#F5BF23', fontWeight: 100, fontSize: 40}}>OUR</span> <span style={{color:'white', fontWeight: 600, fontSize: 40}}>SERVICES</span>
                    </p>
                </div>
                {/* <div className='col-12'>
                    <span style={{color:'white', fontWeight: 400, fontSize: 40}}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</span>
                </div> */}
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <img width="100" height="100" src="https://img.icons8.com/emoji/100/building-construction.png" alt="building-construction"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Construction</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>1</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/bubbles/100/building.png" alt="building"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Renovation</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>2</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/stickers/100/online-maintenance-portal.png" alt="online-maintenance-portal"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Building Maintenance</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>3</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3 my-2'>
                    <div className='card shadow'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-6'>
                                <img width="100" height="100" src="https://img.icons8.com/papercut/100/design.png" alt="design"/>
                                    <span style={{fontWeight: 400, fontSize: 20, color: 'black'}}>Architecture Design</span>
                                </div>
                                <div className='col-md-6 text-center'>
                                    <span style={{color:'#F5BF23', fontWeight: 400, fontSize:80}}>4</span>
                                </div>
                            </div>
                            {/* <div className='col-12'>
                                <p align='justify'>
                                lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.
                                </p>
                                <p>Read More</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Home