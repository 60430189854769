import React from 'react'
import AboutHeaderImage from './Assets/Images/about-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const AboutHeader = () => {
  return (
    <>
      <MDBCarousel>
          <MDBCarouselItem itemId={1}>
              <img src={AboutHeaderImage} className='d-block w-100 home-slider' alt='AboutHeaderImage' />
          </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default AboutHeader